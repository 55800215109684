import { useRef, useMemo, useEffect } from 'react';
import debounce from 'lodash/debounce';

type ControlFunctions = {
  /** Cancels pending function invocations. */
  cancel: () => void;
  /** Immediately invokes pending function invocations. */
  flush: () => void;
  isPending: () => boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DebouncedState<T extends (...args: any) => ReturnType<T>> = ((
  ...args: Parameters<T>
) => ReturnType<T> | undefined) &
  ControlFunctions;

export function useDebounceCallback<T extends (...args: any) => ReturnType<T>>(
  func: T,
  delay = 250
): DebouncedState<T> {
  const debouncedFunc = useRef<ReturnType<typeof debounce>>();

  const debounced = useMemo(() => {
    const debouncedFuncInstance = debounce(func, delay);

    const wrappedFunc: DebouncedState<T> = (...args: Parameters<T>) => {
      return debouncedFuncInstance(...args);
    };

    wrappedFunc.cancel = () => {
      debouncedFuncInstance.cancel();
    };

    wrappedFunc.isPending = () => {
      return !!debouncedFunc.current;
    };

    wrappedFunc.flush = () => {
      return debouncedFuncInstance.flush();
    };

    return wrappedFunc;
  }, [func, delay]);

  useEffect(() => {
    debouncedFunc.current = debounce(func, delay);

    return () => {
      if (debouncedFunc.current) {
        debouncedFunc.current.cancel();
      }
    };
  }, [func, delay]);

  return debounced;
}
