import { useIntl } from 'react-intl';
import * as registrationDispatch from 'redux/ducks/registration';
import { UiActions } from 'redux/ducks/ui';
import './index.scss';
import Register from './Register';
import RegistrationPointSelection from './RegistrationPointSelection';
import { default as NodeHistory } from './History';
import { default as GuestRegistrationHistory } from './GuestRegistrationHistory';

import Helmet from 'react-helmet';
import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as notificationDispatch from 'redux/ducks/notification';
import { Icon } from 'components/Icon';
import { WithRouterProps, withRouter } from 'react-router';
import { getRegistrationPoints } from 'redux/ducks/data/registrationPoints';
import { Slide, Button, Grid, Step, Stepper, StepButton } from '@material-ui/core';
import GuestRegistration from './GuestRegistration';
import { ConnectionType, RegistrationActions, ScaleStatus } from 'redux/ducks/registration';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { SettingsActions } from 'redux/ducks/settings';
import { NotificationActions } from 'redux/ducks/notification';
import logoutImage from 'static/icons/logout.svg';
import RegistrationSuccessModal from 'pages/Registration/RegistrationSuccessModal';
import GuestRegistrationSuccessModal from 'pages/Registration/GuestRegistrationSuccessModal';
import { useAuth } from 'frontend-core';
import LoadingPlaceholder from 'src/components/LoadingPlaceholder';
import { ReasonQuery } from 'redux/ducks/reasons';
import * as reasonActions from 'redux/ducks/reasons';

const StepConnector = () => <ChevronRightIcon />;

const COMPONENTS_BY_STEP = {
  0: RegistrationPointSelection,
  1: Register,
  2: GuestRegistration
};

export interface RegistrationPageProps {
  guestRegistrationOnly?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type OwnProps = StateProps & DispatchProps & WithRouterProps & RegistrationPageProps;

type IScaleConnectionMessage = ScaleStatus;

interface IScaleWeightMessage {
  isMessage: boolean;
  message: string | null;
  weight: number | null;
  unit: string;
  isStable: boolean;
  isLowVoltage: boolean;
  connectionType: ConnectionType;
}

/**
 * Type guard that guarantees the correct type IScaleConnectionMessage
 *
 * @public
 */
function isIScaleConnectionMessage(
  data: IScaleConnectionMessage | IScaleWeightMessage
): data is IScaleConnectionMessage {
  const connectionMessage = data as IScaleConnectionMessage;
  return (
    connectionMessage.hasOwnProperty('isConnected') ||
    connectionMessage.hasOwnProperty('isConnecting')
  );
}

/**
 * Type guard that guarantees the correct type IScaleWeightMessage
 *
 * @public
 */
function isIScaleWeightMessage(
  data: IScaleConnectionMessage | IScaleWeightMessage
): data is IScaleWeightMessage {
  return (data as IScaleWeightMessage).weight !== undefined;
}

const RegistrationPage: React.FunctionComponent<OwnProps> = (props) => {
  const { canUseBluetooth, location, resetStepper, locale, guestRegistrationOnly, enableAutoTare } =
    props;
  const intl = useIntl();
  const { logout } = useAuth();

  React.useEffect(() => {
    if (!guestRegistrationOnly) {
      fetchData();
    }
  }, [guestRegistrationOnly]);

  React.useEffect(() => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ canUseBluetooth: canUseBluetooth }));
    }
  }, [canUseBluetooth]);

  React.useEffect(() => {
    window.addEventListener('message', onReceivePostMessage);

    return () => {
      window.removeEventListener('message', onReceivePostMessage);
    };
  }, []);

  React.useEffect(() => {
    resetStepper();
  }, [location.key]);

  React.useEffect(() => {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      triggerScaleNotification(props.scaleStatus.type);
    }
  }, [props.scaleStatus.isConnected]);

  const fetchData = () => {
    const { getRegistrationPoints, resetStepper, getAll } = props;

    resetStepper();
    void getRegistrationPoints();
    void getAll();
  };

  const handleLogout = () => {
    void logout({ locale });
  };

  /**
   * Handles the "message" event
   *
   * @param {MessageEvent} event - MessageEvent containing the data injected by app-scale
   * @public
   */
  const onReceivePostMessage = (event: MessageEvent<ScaleStatus | IScaleWeightMessage>): void => {
    if (
      !event ||
      !event.data ||
      (!isIScaleWeightMessage(event.data) && !isIScaleConnectionMessage(event.data))
    )
      return;

    const { setScaleStatus, scaleStatus } = props;

    if (enableAutoTare && scaleStatus.isConnected && isIScaleWeightMessage(event.data)) {
      if (event.data.weight < 0 && event.data.isStable) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'zero' }));
      }
    }

    if (!scaleStatus.isConnected) {
      if (isIScaleConnectionMessage(event.data)) {
        setScaleStatus({ ...scaleStatus, ...event.data });
      } else {
        // if scale is disconnected by we get weight message, we have connection
        setScaleStatus({ isConnected: true, isConnecting: false, type: event.data.connectionType });
      }
    } else if (isIScaleConnectionMessage(event.data)) {
      setScaleStatus({ ...scaleStatus, ...event.data });
    }
  };

  /**
   * Triggers the correct notification depending on the scale status.
   *
   * @public
   */
  const triggerScaleNotification = (connectionType?: ConnectionType) => {
    const {
      showNotification,
      scaleStatus: { isConnected: isScaleConnected }
    } = props;

    const notification = isScaleConnected
      ? connectionType
        ? intl.formatMessage({
            id: `registration.scale.notification.connected.via${connectionType}`
          })
        : intl.formatMessage({ id: 'registration.scale.notification.connected.viaUSB' })
      : intl.formatMessage({ id: 'registration.scale.notification.disconnected' });

    showNotification(notification);
  };

  const { step, loading, isScaleClient, nodesHistory, updateStepper } = props;

  const StepComponent: React.JSXElementConstructor<any> =
    COMPONENTS_BY_STEP[guestRegistrationOnly ? 2 : step];

  return (
    <div
      className={classNames('registrationPageContainer', {
        isScaleClient: isScaleClient,
        guestRegistrationPage: step === 2
      })}
    >
      <Helmet title={intl.formatMessage({ id: 'registration.headline' })} />
      <Grid container spacing={4}>
        <Grid
          className={classNames('item-selections form-content', {
            overlay: loading,
            'with-scale': isScaleClient
          })}
          xs={12}
          item
        >
          {loading ? <LoadingPlaceholder /> : <StepComponent initRegistrationPoints={fetchData} />}
        </Grid>
        <Grid item xs={12} className='stepper-steps-row'>
          <Stepper
            activeStep={step}
            nonLinear
            connector={<StepConnector />}
            style={{
              overflowX: 'auto',
              overflowY: 'hidden',
              WebkitOverflowScrolling: 'touch',
              padding: 0
            }}
          >
            {step === 2 ? (
              <Slide in={true} direction={'right'} timeout={200}>
                <Step>
                  <StepButton onClick={resetStepper} completed={true}>
                    {intl.formatMessage({ id: 'guestRegistration.other' })}
                  </StepButton>
                </Step>
              </Slide>
            ) : (
              step !== 1 &&
              nodesHistory.map((item, index) => {
                const name = Object.keys(item)[0];
                return (
                  <Slide in={true} direction={'right'} timeout={200} key={`${name}${index}`}>
                    <Step>
                      <StepButton onClick={() => updateStepper(index, name)} completed={true}>
                        {name}
                      </StepButton>
                    </Step>
                  </Slide>
                );
              })
            )}
          </Stepper>
        </Grid>
        <Grid
          className={classNames('form-bar', {
            overlay: loading
          })}
          xs={12}
          item
        >
          {isScaleClient && (
            <Button
              type='button'
              className='logoutBtn'
              startIcon={<Icon icon={logoutImage} />}
              onClick={handleLogout}
            >
              {intl.formatMessage({ id: 'auth.sign_out' })}
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {step !== 2 && guestRegistrationOnly !== true ? (
            <NodeHistory />
          ) : (
            <GuestRegistrationHistory />
          )}
        </Grid>
      </Grid>
      <RegistrationSuccessModal />
      <GuestRegistrationSuccessModal />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  step: state.registration.step,
  loading: state.settings.isInitial,
  isScaleClient: state.user.client === 'scale',
  scaleStatus: state.registration.scaleStatus,
  canUseBluetooth: state.settings.canUseBluetooth,
  nodesHistory: state.registration.nodesHistory,
  currentNodes: state.registration.currentNodes,
  locale: state.settings.locale,
  enableAutoTare: state.settings.enableAutoTare
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    RootState,
    void,
    UiActions | SettingsActions | NotificationActions | RegistrationActions
  >
) => ({
  updateStepper: (index: number, property: string) => {
    dispatch(registrationDispatch.updateStepper(index, property));
  },
  resetStepper: () => dispatch(registrationDispatch.resetStepper()),
  showNotification: (message: string) => {
    dispatch(notificationDispatch.showNotification(message));
  },
  setScaleStatus: (status: ScaleStatus) => {
    dispatch(registrationDispatch.setScaleStatus(status));
  },
  getRegistrationPoints: () =>
    dispatch(getRegistrationPoints({ includeIngredients: true, '$sort[name]': 1 })),
  getAll: (query?: ReasonQuery) => dispatch(reasonActions.getAll(query))
});

export default connect<StateProps, DispatchProps, RegistrationPageProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RegistrationPage));
